<template>
  <b-row class="justify-content-md-center">
    <b-col :cols="getLayoutColum">
      <b-card
        class="border mt-1"
      >
        <b-col
          v-if="isRequireChangePassword"
          cols="12"
        >
          <b-alert
            variant="primary"
            show
          >
            <div class="alert-body">
              <span><strong>{{ $t('Please Change Password') }}!</strong></span>
            </div>
          </b-alert>
        </b-col>

        <b-card-header class="p-1">
          <b-card-title class="font-medium-2">
            <feather-icon
              icon="LockIcon"
              size="18"
            />
            <span class="align-middle ml-50">{{ $t('Change Password') }}</span>
          </b-card-title>
        </b-card-header>
        <b-col cols="12">
          <validation-observer ref="form">
            <!-- form -->
            <b-form autocomplete="off">
              <b-row>
                <!-- old password -->
                <b-col md="6">
                  <b-form-group>
                    <label class="font-weight-bolder">{{ $t('Old Password') }}</label>
                    <validation-provider
                      #default="{ errors }"
                      name="Old Password"
                      :rules="oldPasswordRules"
                    >
                      <b-input-group class="input-group-merge">
                        <b-form-input
                          ref="Old Password"
                          v-model="passwordValueOld"
                          :state="errors.length > 0 ? false:null"
                          :type="passwordFieldTypeOld"
                          :placeholder="$t('Old Password')"
                          autocomplete="off"
                        />
                        <b-input-group-append is-text>
                          <feather-icon
                            :icon="passwordToggleIconOld"
                            class="cursor-pointer"
                            @click="togglePasswordOld"
                          />
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!--/ old password -->
              </b-row>
              <b-row>
                <!-- new password -->
                <b-col md="6">
                  <b-form-group>
                    <label class="font-weight-bolder">{{ $t('New Password') }}</label>
                    <validation-provider
                      #default="{ errors }"
                      name="New Password"
                      :rules="passwordRules"
                    >
                      <b-input-group class="input-group-merge">
                        <b-form-input
                          ref="New Password"
                          v-model="newPasswordValue"
                          :state="errors.length > 0 ? false:null"
                          :type="passwordFieldTypeNew"
                          :placeholder="$t('New Password')"
                          autocomplete="off"
                        />
                        <b-input-group-append is-text>
                          <feather-icon
                            :icon="passwordToggleIconNew"
                            class="cursor-pointer"
                            @click="togglePasswordNew"
                          />
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!--/ new password -->

                <!-- retype password -->
                <b-col md="6">
                  <b-form-group>
                    <label class="font-weight-bolder">{{ $t('Confirm New Password') }}</label>
                    <validation-provider
                      #default="{ errors }"
                      name="Confirm New Password"
                      rules="confirmed:New Password"
                    >
                      <b-input-group class="input-group-merge">
                        <b-form-input
                          v-model="RetypePassword"
                          :state="errors.length > 0 ? false:null"
                          :type="passwordFieldTypeRetype"
                          :placeholder="$t('Confirm New Password')"
                          autocomplete="off"
                        />
                        <b-input-group-append is-text>
                          <feather-icon
                            :icon="passwordToggleIconRetype"
                            class="cursor-pointer"
                            @click="togglePasswordRetype"
                          />
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!--/ retype password -->

                <!-- buttons -->
                <b-col cols="12">
                  <b-button
                    variant="dark"
                    class="btn-icon"
                    size="lg"
                    :disabled="$wait.is('loading-on-save')"
                    @click="onSave"
                  >
                    <feather-icon icon="SaveIcon" />
                    {{ $t('Save') }}
                  </b-button>

                  <b-button
                    variant="outline-secondary"
                    class="btn-icon ml-2"
                    size="lg"
                    :disabled="$wait.is('loading-on-save')"
                    type="reset"
                  >
                    <feather-icon icon="DeleteIcon" />
                    {{ $t('Reset') }}
                  </b-button>
                </b-col>
                <!--/ buttons -->
              </b-row>
            </b-form>
          </validation-observer>
        </b-col>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  ValidationProvider, ValidationObserver, localize,
} from 'vee-validate'
import { required } from "@validations";
import store from '@/store'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      required,
      passwordValueOld: '',
      newPasswordValue: '',
      RetypePassword: '',
      passwordFieldTypeOld: 'password',
      passwordFieldTypeNew: 'password',
      passwordFieldTypeRetype: 'password',
    }
  },
  computed: {
    isRequireChangePassword() {
      return this.$route.name === 'requireChangePassword'
    },
    getLayoutColum() {
      return this.isRequireChangePassword ? '6' : '12'
    },
    userData() {
      return store.getters['auth/user']
    },
    oldPasswordRules() {
      return 'required|min:6|max:16'
    },
    passwordRules() {
      return 'required|min:6|max:16|password'
    },
    passwordToggleIconOld() {
      return this.passwordFieldTypeOld === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    togglePasswordOld() {
      this.passwordFieldTypeOld = this.passwordFieldTypeOld === 'password' ? 'text' : 'password'
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
    },
    clearForm() {
      this.passwordValueOld = ''
      this.newPasswordValue = ''
      this.RetypePassword = ''
      this.$refs['Old Password'].focus()
      setTimeout(() => {
        this.$refs.form.reset()
      }, 100)
    },
    async onSave() {
      const isValidForm = await this.$refs.form.validate()
      if (!isValidForm) {
        return
      }

      const formData = {
        oldPassword: this.passwordValueOld,
        newPassword: this.newPasswordValue,
      }

      this.$wait.start('loading-on-save')
      try {
        const { data } = await this.$http.post('/auth/changePassword', formData)
        if (data.success) {
          this.$swal({
            icon: 'success',
            title: this.$t('ACCOUNT_SAVE_SUCCESS'),
          }).then(() => {
            this.clearForm()
            if (this.isRequireChangePassword) {
              this.$router
                .replace({ name: 'profile' })
            }
          })
          store.commit('auth/setRequireChangePassword', false)
        } else {
          this.$swal({
            icon: 'error',
            title: this.$t(data.status.message || 'Error'),
          })
        }
      } finally {
        this.$wait.end('loading-on-save')
      }
    },
  },
}
</script>
